<template>
    <div class="wlm-author">
        <div class="landing-title-group">
            <div id="authorSectionScrollMarker"
                 class="landing-title-group__hidden-marker"
            ></div>
            <div class="landing-title-group__small">
                САМ СЕБЕ ПРОДЮСЕР
            </div>
            <div class="landing-title-group__mid">История моего успеха!</div>
        </div>

        <div class="wlm-author__content">

            <div class="wlm-author__text">
                <div
                        class="wlm-author__text-content"
                        :class="{'isOpened': isOpened}"
                >
                    <div class="wlm-author__image"></div>
                    <div class="wlm-author__title">Андрей Сягло</div>
                    <div class="wlm-author__subtitle">
                        2 высших образования, 8 лет
                        работал программистом в найме, с 2016 года работаю на себя
                    </div>
                    <div class="wlm-author__subtitle dot-only">.....</div>
                    <p>
                        <span>Я очень хорошо помню тот &ldquo;знаковый&rdquo; день в своей жизни.
                        Я сидел в рабочем кабинете и решал очередные &ldquo;важные&rdquo;
                        дела по работе, которые никто кроме меня не решит.</span>
                    </p>

                    <p>
                        <span>Тогда у меня был небольшой бизнес,
                            которым я очень гордился. Ведь он так хорошо продуман,
                            я внедрил в него самые передовые технологии,
                            CRM, сквозную аналитику, таблички, графики.
                            В нем было все хорошо, кроме двух моментов -
                            я работал по 12 часов в день без выходных и
                            мало зарабатывал.</span>
                    </p>

                    <p>
                        <span>В тот день я словил себя на мысли, что больше так не хочу,
                            что я делаю бизнес ради бизнеса.
                            Тогда я принял решение целую неделю не
                            притрагиваться к делам, все свои задачи
                            передать менеджерам и просто ни о чем не думать.</span>
                    </p>

                    <p>
                        <span>Уже на 5-й день ничегонеделания я начал получать
                            от этого удовольствие, в голову стали приходить
                            необычные мысли, идеи. Вдруг я вспомнил,
                            что моя жена увлеклась психологией, прошла
                            обучение и проводит какие-то консультации за донэйшн.
                            Нашей дочке только исполнился 1 год, а она проводит консультации.
                            Ходит по квартире, улыбается, песенки поёт.
                            Я почитал её переписки с клиентами,
                            те тоже довольные, пишут благодарственные отзывы,
                            просят записать на повторные консультации.</span>
                    </p>

                    <p>
                        <span>У меня оставалось еще 2 дня отдыха и я решил провести эксперимент.
                            А что, если перенаправить все деньги,
                            которые я вкладываю в рекламу
                            основного бизнеса, в новый
                            инстаграм моей жены по психологии.
                            У меня не было никакой цели, просто было
                            свободное время и я решил поиграть. Я умел
                            настраивать таргетированную рекламу,
                            за несколько часов настроил объявления,
                            отправил их на модерацию и пошел дальше отдыхать.
                        </span>
                        <br />
                        <br />
                        <strong>Идея на миллион</strong></p>
                    <p>
                        <span>Через несколько часов мне позвонила жена,
                            сказала что к ней откуда-то &ldquo;повалило&rdquo;
                            много подписчиков и спросила могу ли я завтра и
                            послезавтра посидеть с ребенком,
                            потому что пришло много запросов на консультацию.
                        </span>
                    </p>

                    <p>
                        <span>Я бросил трубку и побежал смотреть рекламный кабинет.
                            Прикольно, а так можно было!? В первый же день реклама
                            показала себя очень хорошо. Мне было с чем сравнивать,
                            я до этого два года настраивал рекламу для своего основного
                            бизнеса. Я понял, что тут есть большой потенциал и решил
                            еще глубже изучить это направление.
                        </span>
                    </p>

                    <p>
                        <span>Основной бизнес к моему удивлению
                            уже почти неделю справлялся без меня и
                            я решил продлить наше &ldquo;расставание&rdquo;.
                            Пошел к жене и сказал, что у меня к ней деловое
                            предложение по сотрудничеству.
                            С первых же минут не всё пошло гладко...
                            Главная претензия жены была в том, что она не хочет
                            себя чувствовать &ldquo;лошадкой&rdquo;, которая будет
                            отрабатывать на консультациях вложенные в рекламу деньги.
                            Я с ней согласился. Все, что я знал на тот момент,
                            что эту тему надо дальше исследовать и что решение точно есть.
                        </span>

                    </p>

                    <p>
                        <span>Я проснулся в 4 часа ночи и меня бросило в жар,
                            ведь только что во сне ко мне пришла
                            &ldquo;Идея на миллион&rdquo;.
                            Такого прилива энергии я не ощущал никогда,
                            это было самое бодрое утро за всю мою жизнь.
                            Я не стал дожидаться пока проснется жена
                            и в 6 утра сам её разбудил. Первое, что я ей сказал:</span>
                    </p>

                    <ul>
                        <li>
                            <p><span>Лена, полетели в Барселону.</span></p>
                        </li>
                        <li>
                            <p><span>Когда?</span></p>
                        </li>
                        <li>
                            <p><span>Через неделю, максимум через две.</span></p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Мы же планируем через 3 месяца в Таиланд
                                    на зимовку и у нас пока нету денег даже на него.
                                </span>
                            </p>
                        </li>
                    </ul>

                    <p><span>
                        Я рассказал жене про свой сон.
                        Поскольку я нашел вариант,
                        как привлекать дешево подписчиков в Инстаграм,
                        нам надо придумать какой-то полезный продукт
                        для них за $1 и когда мы привлечем миллион подписчиков,
                        у нас будет миллион долларов.</span><br />
                    </p>

                    <ul>
                        <li>
                            <p>
                                <span>Миллион долларов хорошо,
                                    а в Барселону мы полетим или нет?
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Да, у нас две недели в запасе.
                                    Ты думай над продуктом, а я буду пока набирать подписчиков.
                                </span>
                            </p>
                        </li>
                    </ul>

                    <p><span>
                        В те дни мы много разговаривали про Барселону,
                        я совсем забыл про свой основной бизнес
                        и ни про что кроме сангрии и паэлье не мог думать.
                        Меня переполняли чувства внутреннего
                        подъема и наполненности.</span><br />
                        <br />
                        <strong>Важные решения и первые сомнения</strong>
                    </p>

                    <p>
                        <span>
                            По вечерам
                            мы с женой гуляли по
                            улице с коляской и вместе думали над нашим
                            новым проектом.
                        </span>
                    </p>

                    <ul>
                        <li>
                            <p><span>Медитация</span></p>
                        </li>
                        <li>
                            <p><span>Что медитация?</span></p>
                        </li>
                        <li>
                            <p><span>Я хочу записать медитацию</span></p>
                        </li>
                        <li>
                            <p>
                                <span>Это какая-то фигня,
                                    давай что-нибудь нормальное
                                    придумаем</span>
                            </p>
                        </li>
                        <li>
                            <p><span>Я лучше знаю, я так чувствую!</span></p>
                        </li>
                    </ul>

                    <p>
                        <span>
                            Жена придумала создать медитацию
                            для женщин, у которых не получается
                            быстро забеременеть.
                            Она сама прошла через 3 года бесплодия,
                            медитации ей помогли в свое время,
                            значит это работает. Мы решили,
                            что я завтра целый день проведу
                            с ребенком, а жена будет думать
                            над медитацией.
                        </span>
                    </p>

                    <p>
                        <span>Тем временем у нас в аккаунте инстаграм
                            было уже несколько тысяч подписчиков,
                            они прибавлялись каждый день по 500+,
                            вкладывал в рекламу по $100 в день.
                            Я нахаживал километры по улице с коляской,
                            думал над технической частью, с программистом
                            по телефону обсудили простенький
                            сайт для медитации.
                        </span><br />
                        </p>

                    <ul>
                        <li>
                            <p>
                                <span>
                                    Андрей, если тебе не горит,
                                    я за 5-7 дней сделаю сайт и &ldquo;отправим в прод&rdquo;.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Нет, это долго,
                                    давай сделаем за два дня!?
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Хм&hellip; ну ок ок,
                                    я подумаю, как ускориться))
                                </span><br />
                                </p>
                        </li>
                    </ul>

                    <p>
                        <span>
                            Ноябрь месяц, на улице холодно,
                            но я не замечаю, как три часа
                            без остановки колешу по дворам
                            Минска с коляской.
                            У меня на лице улыбка,
                            внутри какая-то уверенность,
                            что прямо сейчас происходит что-то очень
                            важное.
                            Жена в потоке думает над медитацией,
                            программист работает над сайтом.
                        </span><br />
                        </p>

                    <p>
                        <span>Присел на лавочку и в голову
                            внезапно пришла плохая мысль: &ldquo;
                            Ведь я же пошутил жене про Барселону((.
                            Какая Барселона, да и зачем... Стоп!
                            Без неё теряется вся магия происходящего.
                            Барселона нужна, чтобы быть в потоке,
                            чтобы не погаснуть. Ты не пошутил!&rdquo;.
                        </span><br />
                    </p>

                    <p>
                        <span>
                            Действительно, сколько раз у
                            каждого из нас в голове возникали
                            &ldquo;сумасшедшие&rdquo; идеи на миллион?!
                            Но они как появлялись, так быстро
                            и &ldquo;растворялись&rdquo; в
                            суете событий. А если друзьям
                            или родственникам рассказать,
                            так те быстро всё обесценят,
                            скажут, что такое уже давно придумали
                            и найдут миллион причин почему идея
                            на миллион не взлетит.
                        </span>
                        <br />
                        <br />
                        <strong>Работа над продуктом</strong></p>

                    <p>
                        <span>Дома меня встречает
                            жена и разливается в улыбке:</span>
                        <br />
                        </p>

                    <ul>
                        <li>
                            <p>
                                <span>Мне кажется, я это сделала,
                                    я написала слова, теперь надо красиво
                                    прочитать в микрофон, должно
                                    получиться волшебно! Но у нас нету
                                    нормального микрофона, надо искать
                                    студию звукозаписи.</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Стоп, какая студия,
                                    мы завтра уже должны запустить сайт.
                                    Непонятно вообще что мы делаем,
                                    может мы занимаемся ерундой.
                                    Давай без студии запишем просто дома
                                    на диктофон. Если взлетит,
                                    перепишем в профессиональной студии.</span>
                            </p>
                        </li>
                        <li>
                            <p><span>Хорошо, а что с музыкой.</span></p>
                        </li>
                        <li>
                            <p>
                                <span>Блин,
                                это же еще музыку надо накладывать.
                                Поиграй с Софией, я пойду изучать эту тему.
                                </span><br />
                            </p>
                        </li>
                    </ul>

                    <p>
                        <span>Мне хватило несколько
                            часов, чтобы выбрать
                            правильную программу
                            для редактирования музыки
                            и посмотреть несколько обучающих
                            роликов на youtube. Мне кажется,
                            в тот момент я был в таком состоянии,
                            что мог бы за пару часов научиться
                            управлять самолетом. Я был максимально
                            открыт ко всему новому. Мне во что бы то
                            ни стало надо было сложить
                            этот &ldquo;пазл&rdquo; до
                            конца и запустить сайт с медитацией.
                        </span>
                    </p>

                    <p>
                        <span>
                            Жена в тишине &ldquo;наговорила&rdquo; слова медитации,
                            я загрузил их в программу и убрал шумы.
                            Потом начал думать над музыкой.
                            Первое, что пришло в голову, наложить
                            звук моря или ручейка, так делают
                            большинство людей на ютюбе.
                        </span>
                        </p>

                    <ul>
                        <li>
                            <p><span>Лена, послушай, что получилось!?</span></p>
                        </li>
                        <li>
                            <p><span>Давай&hellip;</span></p>
                        </li>
                        <li>
                            <p><span>Мне норм, как тебе?</span><br />
                                <span>...</span></p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Получилось ужасно,
                                    сюда надо что-то другое,
                                    что-то погружающее, море отвлекает&hellip;
                                </span>
                                <span>Я вспомнила,
                                    вот эту давай попробуем&hellip;.</span>
                                <br />
                                <span>...</span></p>
                        </li>
                        <li>
                            <p>
                                <span>
                                    Вау! Это фантастика!
                                    Как же она хорошо &ldquo;легла&rdquo;
                                    на твои слова.
                                    Я хочу тебя расцеловать!
                                </span>
                            </p>
                        </li>
                    </ul>

                    <p>
                        <span>
                            Она показала мне одну волшебную
                            мелодию, её создал автор,
                            который живет на сказочном
                            острове Бали. К счастью,
                            оказалось, что у него был
                            сайт, где он продавал лицензии
                            на свою музыку. Я купил лицензию
                            и в программе наложил музыку на слова.
                        </span>
                    </p>

                    <p>
                        <span>Я просто лежал на кровати
                            и слушал эту невероятную медитацию.
                            Неужели мы это сделали? У нас ведь
                            ничего не было, просто мысли, просто
                            наша идея, и вот она материализовалась.
                        </span><br />
                        <br />
                        <strong>Идея провалилась</strong></p>

                    <p>
                        <span>Класс! В аккаунте у нас уже более 3000 подписчиков.
                            Мы сделаем стоимость по $1 и заработаем $3000
                            на Барселону.
                            Схожу в магаз за тортиком,
                            надо отметить это дело!
                        </span><br />
                        </p>

                    <p><span>Я шел по магазину вдоль бутиков и понимал,
                        что скоро смогу себе позволить не думать
                        о деньгах, заходить в любой бутик и
                        покупать всё что захочу. В тот момент я
                        был настолько &ldquo;опьянен&rdquo; своей
                        идеей, что весь мир был у моих ног.
                    </span><br />

                    </p>

                    <p>
                        <span>Наступил день X, мы запустили сайт.
                            Сделали анонс медитации
                            в Инстаграм. Ждем&hellip;</span><br />
                        </p>

                    <ul>
                        <li>
                            <p>
                                <span>Ну что там?
                                    - спросила жена через 2 часа.</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Всё нормально,
                                    надо еще подождать&hellip;.</span><br />
                                </p>
                        </li>
                    </ul>

                    <p><span>
                        Я сидел за рабочим столом и
                        чувствовал, как &ldquo;рушились&rdquo;
                        все мои надежды на успех.
                        В голове пролетали мысли: &ldquo;
                        У нас ничего не получилось.
                        Это никому не нужно.&rdquo;
                        Но еще через 15 минут пришла первая
                        оплата в платежную систему.
                        Это меня вернуло в чувства,
                        снова появилась какая-то надежда.</span><br />
                        </p>

                    <p>
                        <span>Прошли сутки после запуска.
                            Я начал считать. За сутки пришло 25 заказов.
                            Оказалось, что платежная система с одного доллара
                            25 центов берет себе.
                            То есть мы заработали 75 центов * 25 = 18.75 долларов.
                            А вкладываю в рекламу я $100 в день.
                        </span>
                    </p>

                    <p>
                        <span>Первая мысль,
                            которая пролетела в голове: &ldquo;
                            Срочно отключать рекламу!&rdquo;
                            Но отключить рекламу, это значит отказаться
                            от нашей идеи и переходить
                            обратно с головой в свой основной
                            бизнес у которого нет никаких перспектив.
                        </span><br />
                        </p>

                    <ul>
                        <li>
                            <p>
                                <span>Лена, наша идея с $1 провалилась!
                                    Нам надо сделать такую цену медитации,
                                    чтобы при 25-30 заказах мы хотя бы окупали
                                    вложенные в рекламу деньги. Это около $4</span>
                            </p>
                        </li>
                        <li>
                            <p><span>
                                Мне только что написали в директ
                                первый отзыв на медитацию, сказали,
                                что она &ldquo;волшебная&rdquo;!
                                Да, давай сделаем стоимость больше.</span>
                                <br />
                                </p>
                        </li>
                    </ul>

                    <p><span>
                        Те дни были как &ldquo;на качелях&rdquo;.
                        Оказалось, что когда я поднял цену,
                        стало приходить не 25 заказов, а 10.
                        Потом я придумал добавить на сайт отзывы,
                        чтобы улучшить конверсию.
                        Отзывов приходило всё больше это радовало.
                        Но математика всё равно не сходилась,
                        мы уходили в минус.</span><br />
                        </p>

                    <ul>
                        <li>
                            <p>
                                <span>
                                    Лена, мы не полетим в Барселону,
                                    мы облажались, мне надо возвращаться
                                    обратно на старый проект, чтобы
                                    &ldquo;кормить семью&rdquo;.
                                </span>
                            </p>
                        </li>
                        <li>
                            <p><span>
                                Ты знаешь дорогой, мне кажется,
                                что я уже там побывала. Мы с тобой так много
                                о ней говорили и визуализировали,
                                что я прошла все улицы этого прекрасного города,
                                посетила все достопримечательности.
                                Барселона сама к нам прилетела,
                                это были незабываемые дни&hellip;
                                Ну ничего, у меня есть моё любимое
                                дело, моя психосоматика,
                                буду проводить консультации,
                                писать посты.</span><br />
                                </p>
                        </li>
                    </ul>

                    <p>
                        <span>Она ушла к ребенку,
                            стараясь не показывать разочарование.</span><br />
                        <br />
                        <strong>Двигаемся дальше</strong></p>

                    <p>
                        <span>
                            Я полдня проходил в печали и
                            к вечеру вспомнил стишок,
                            который когда-то в юности сочинил:</span><br />
                        <br />
                        <span>Я любую для тебя звезду достану</span><br />
                        <span>Буду землю грызть пока стою на ней</span><br />
                        <span>Чтобы сделать тебя самой-самой</span><br />
                        <span>И купаться в счастье наших дней.</span><br />
                        <br />
                        <span>
                            - Какого хрена я кисну!
                            Ну не летим мы в Барселону,
                            хорошо, тут я облажался.
                            Но эта идея привела нас в эту точку,
                            где мы сейчас находимся.
                            Это уже лучше, чем неделю назад,
                            когда у нас вообще ничего не было.
                            У нас есть сайт, есть продукт, есть отзывы,
                            мы уже сделали несколько ошибок
                            и они тоже поучительны.
                            Надо двигаться дальше, надо выбросить из
                            головы мысли про неудачу и с
                            чистой головой принимать решения.
                        </span><br />
                        </p>

                    <p><span>
                        Я пошел читать истории успеха тех,
                        кто запускал инфопродукты.
                        Кейсы, кейсы, так много успешных кейсов.
                        У нас тоже получится.
                        Нам уже каждый день пишут обратную связь
                        в директ, медитация людям нравится. </span>
                        <br />
                        <br />
                        <span>
                            В тот вечер в душе я словил
                            поток и мне пришло сразу две идеи:</span>
                    </p>

                    <ul>
                        <li>
                            <p>
                                <span>
                                    создать чат-телеграм для девушек,
                                    которые послушали медитацию,
                                    чтобы у них было место для общения,
                                    там они смогут рассказать свои
                                    истории и поделиться впечатлениями
                                    о медитации.</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>создать еще одну медитацию для женщин.
                                    Так люди, которые купили первую медитацию
                                    и она им понравилась, смогут купить вторую
                                    и у нас будет расти LTV (жизненная ценность клиента).
                                    А новые клиенты смогут покупать сразу две медитации
                                    и будет расти средний чек.
                                </span>
                                <br />
                                </p>
                        </li>
                    </ul>

                    <p><span
                    >Каждый день мы придумывали что-то новое,
                        улучшали наш сайт, поднимали активность
                        аудитории в Инстаграм, экспериментировали
                        с таргетингом. </span><br />
                        <br />
                        <strong>Всё только начинается!</strong></p>

                    <p><span>
                        Прошло 3 месяца&hellip; Мы собрали вещи,
                        упаковали чемоданы и ночью вылетаем в Таиланд.
                        Ведь еще 3 месяца назад мы не были уверены,
                        что сможем это сделать, у нас не было денег.
                        А сейчас наш проект вышел в плюс и
                        продолжает потихоньку расти&hellip;</span>
                    </p>

                    <p><span>
                        Здесь на Родине остается наша любимая квартира
                        в 47 метров на 18 этаже,
                        в которой всё начиналось.
                        Свадьба, рождение дочки,
                        рождение нового проекта&hellip;</span>
                    </p>

                    <p><span>
                        Здесь на Родине мы оставляем нерешенный
                        вопрос с долгом. Да-да, все свои предыдущие
                        накопления мы &ldquo;инвестировали&rdquo;
                        больше года назад - отдали дальнему
                        родственнику и лучшему другу под
                        проценты в их &ldquo;бизнесы&rdquo;.
                        И один и второй пропали вместе с деньгами.
                        Год хождения по судам ничего не дал.
                        Я решил написать доверенность на юриста и
                        поручил ему заниматься этими делами.
                        Я не хотел держать в голове проблемы.
                    </span></p>

                    <p><span>
                        Мы взяли билет в один конец.
                        Я был невероятно счастлив,
                        что случился тот &ldquo;знаковый&rdquo;
                        день в моей жизни, когда я решился на перемены,
                        с &ldquo;пустой головой&rdquo;
                        заметил начинания своей жены, оказался &ldquo;гибким&rdquo;
                        и предложил ей сотрудничать,
                        во сне увидел &ldquo;невероятную идею на миллион&rdquo;,
                        придумал мотиватор &ldquo;Барселону&rdquo;,
                        который помог не &ldquo;растрясти&rdquo; идею и довести
                        её до MVP (минимально жизнеспособный продукт),
                        вдохновлял себя дальше и не сдавался.
                        Я не знаю сработала бы эта магия если бы на
                        тот момент у меня было все хорошо в жизни,
                        не было проблем с деньгами, вероятно, нет.</span>
                    </p>

                    <p><span>
                        С того времени прошло больше двух лет,
                        но каждые полгода я ловлю себя на мысли и
                        повторяю одну фразу: &ldquo;
                        Лена, а ведь у нас все только начинается.
                        Всё самое лучшее впереди!&rdquo;</span>
                    </p>

                    <p><span>
                        Действительно,
                        если постоянно быть в таком потоке
                        развития и двигаться вперед, жизнь
                        будет становиться только лучше.</span>
                    </p>

                    <p><span>
                        Я полюбил общаться с женой,
                        за чашкой кофе мы часто вылавливаем
                        из воздуха новые идеи и пропускаем их через себя.
                        Мы не замечаем пандемию, короновирус,
                        там нет нашей энергии.
                        Мы видим возможности,
                        выбираем жить в радости,
                        не перегружаем себя рутиной.</span>
                    </p>

                    <p>
                        <span>Когда-то мы были офисные
                            работники, зашореные, с кучей
                            ограничений, ждали в конце месяца зарплату.
                            Сейчас у нас другая жизнь, непредсказуемая,
                            совсем не очевидная. Мы не знаем, что будет
                            через год и тем более через пять.
                            В этой неизвестности я вижу большой потенциал
                            к росту, к развитию. Я заряжаюсь
                            от энергетики моей жены, она от моей.
                            Мы несем этот огонь дальше!</span></p>

                    <p><br />
                        <span>
                            В этой истории я рассказал как всё начиналось.
                            Дальше был длинный путь выстраивания бизнеса,
                            делегирования, принятия решений, ошибок, много всего.
                            Весь свой опыт я упаковал в удобном виде и хочу поделиться им с вами.
                        </span></p>

                    <div class="wlm-author__image bottom isMobile"></div>
                </div>
                <div
                        class="wlm-author__read-more"
                        :class="{'isOpened': isOpened}"
                        @click="toggleText()"
                >
                    <template v-if="isOpened">Свернуть</template>
                    <template v-else>Читать больше</template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'WelcomeLandingAuthor',
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
  },
  methods: {
    toggleText() {
      this.isOpened = !this.isOpened;

      if (!this.isOpened) {
        const readMore = document.querySelector('.wlm-author');

        setTimeout(() => {
          readMore.scrollIntoView({ block: 'end' });
        }, 340);
      }
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";

.wlm-author {
    padding: 30px 24px 20px 24px;
    background: $white;

    @media only screen and (max-width: 767px) {
        padding-top: 12px;
    }

    &__descr-title {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 40px;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }

    &__descr-table {
        max-width: 500px;
        margin: 40px auto;

        @media only screen and (max-width: 767px) {
           margin: 25px 0;
        }

        td, th {
            border-bottom: solid $lightGray 1px;
            padding: 15px 35px;

            @media only screen and (max-width: 767px) {
                padding: 15px 35px 15px 0;
            }
        }

        th {
            font-weight: 600;
            font-size: 20px;
            text-align: left;

            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }

            .large-char {
                font-size: 30px;

                @media only screen and (max-width: 767px) {
                    font-size: 26px;
                }
            }
        }
    }

    &__images-list {
        display: flex;
        justify-content: center;
        margin: 50px 0!important;

        @media only screen and (max-width: 767px) {
            margin: 20px 0!important;
        }

        img {
            max-height: 280px;
            border-radius: 10px;
            flex-shrink: 0;
            flex-grow: 0;
            margin: 0 20px;
            display: block;

            @media only screen and (max-width: 767px) {
                max-height: 160px;
                margin: 0 10px;
            }

            &:first-child {
                border-radius: 50%;
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;

        @media only screen and (max-width: 767px) {
            flex-direction: column;
            margin-top: 30px;
        }
    }

    &__image {
        width: 200px;
        height: 290px;
        background: url("/images/landing/welcome-about-author.jpg") 50% 50%;
        background-size: contain;
        border-radius: 120px;
        flex-shrink: 0;
        margin-right: 90px;
        margin-left: 20px;
        position: relative;
        float: left;
        margin-bottom: 50px;
        margin-top: 50px;

        @media only screen and (max-width: 767px) {
            margin: 0 auto 25px auto;
            float: none;
        }

        &.bottom {
            float: right;
            margin-right: 30px;
            margin-left: 100px;
            margin-top: 20px;
            background: url("/images/landing/welcome-about-bottom.png") 50% 50%;
            background-size: cover;

            @media only screen and (max-width: 767px) {
                display: none;
                margin: 40px auto;
                float: none;
            }

            &.isMobile {
                display: none;

                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }
        }

        &:after,
        &:before {
            position: absolute;
            left: -20px;
            top: 50%;
            width: 7px;
            height: 7px;
            background: #D0D6E7;
            content: '';
            border-radius: 50%;
            margin-top: -3px;
        }

        &:before {
            left: auto;
            right: -20px;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;

        @media only screen and (max-width: 767px) {
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 10px;
        }
    }

    &__subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;

        @media only screen and (max-width: 767px) {
            text-align: center;
            font-size: 14px;
            line-height: 20px;
        }

        &.dot-only {
            letter-spacing: 3px;
            font-size: 30px;
            margin-bottom: 25px;
            display: block;
        }
    }

    &__read-more {
        font-weight: 400;
        font-size: 18px;
        color: $main-blue;
        cursor: pointer;
        display: block;
        text-align: center;
        width: 200px;
        margin: 5px auto;

        @media only screen and (max-width: 767px) {
            width: 100%;
            font-size: 14px;
            line-height: 20px;
        }

        &.isOpened {
            &:after {
                transform: rotate(180deg);
            }
        }

        &:after {
            content: '';
            background: url("/images/landing/arrow-blue.svg");
            display: block;
            width: 12px;
            height: 7px;
            margin: 10px auto 0 auto;

        }
    }

    &__text-content {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        max-height: 430px;
        overflow: hidden;
        position: relative;
        transition: all 300ms;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
            max-height: 700px;
        }

        &.isOpened {
            max-height: 15000px;

            &:after {
                opacity: 0;
            }
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 90px;
            width: 100%;
            background: linear-gradient(360deg, #FFFFFF 33.33%, rgba(255, 255, 255, 0) 88.54%);;
            display: block;
            content: '';
        }
    }

    &__text {
        p {
            margin-bottom: 20px;
        }

        strong {
            font-weight: 900;
        }

        ul {
            list-style: disc;
            margin: 30px 0;

            li {
                list-style: disc;
                margin: 14px 0 14px 30px;
                font-style: italic;
                display: block;
                position: relative;

                p {
                    margin: 0;
                    display: inline;
                }

                &:before {
                    content: '-';
                    position: absolute;
                    left: -12px;
                }
            }
        }
    }
}
</style>
